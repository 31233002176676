import { graphql, Link, useStaticQuery } from 'gatsby'
import * as React from 'react'
import { useMemo } from 'react'
import PostSeoComponent from '../../components/molecules/post-seo/post-seo-component'
import TagListComponent from '../../components/molecules/tag-list/tag-list-component'
import DateHelper from '../../helpers/date-helper'
import MainLayout from '../../layouts/main-layout'

const Index = () => {
  const { allMdx } = useStaticQuery(graphql`
    {
      allMdx(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { frontmatter: { path: { regex: "/^/posts/" } } }
      ) {
        totalCount
        nodes {
          timeToRead
          frontmatter {
            date
            path
            title
            description
            tags
          }
        }
      }
    }
  `)

  const renderPostsData = useMemo(() => {
    if (!allMdx || !allMdx.nodes || allMdx.nodes === []) {
      return []
    }

    return allMdx.nodes.map((node: any) => {
      const infoLine = `${DateHelper.toUiDateString(node?.frontmatter?.date)} · ${node?.timeToRead} min read`

      return (
        <div className="mb-3" key={node?.frontmatter?.path}>
          <Link to={node?.frontmatter?.path}>
            <h2 className="title-section mb-1 post-index__title">
              {node?.frontmatter?.title}
            </h2>
          </Link>
          {node?.frontmatter?.tags ? <TagListComponent className="m-0" tagNames={node?.frontmatter?.tags} /> : null}
          <Link
            to={node?.frontmatter?.path}
            className="t-primary no-link-hover-primary"
          >
            <p className="t-primary no-link-hover-primary p-0 m-0 mb-1">
              {node?.frontmatter?.description}
            </p>
          </Link>
          <p className="t-tiny">{infoLine}</p>
        </div>
      )
    })
  }, [allMdx])

  return (
    <MainLayout>
      <PostSeoComponent title='Posts' description='All my posts.' path='/posts'/>
      <div className="post-index">{renderPostsData}</div>
    </MainLayout>
  )
}

export default Index
